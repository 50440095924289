.tech {
  margin-bottom: .78rem;
  position: relative;
  .scrollHeight {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    pointer-events: none;
  }
  .innerWrap {
    width: 100vw;
    height: 6.39rem;
  }

  .content {
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    .textWrap,
    .textUnderWrap {
      will-change: opacity;
      margin-left: .2rem;
      margin-right: 0;
      .bigText {
        color: #1E2833;
        font-style: normal;
        font-weight: 500;
        line-height: 1.26; /* 27.72px */
        font-size: .27rem;
      }
      .date {
        color: #1E2833;
        font-family: 'Fabrikat-Regular','Fabrikat';
        font-size: .14rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: .17rem;
      }
    }
    .textUnderWrap {
      opacity: 0;
    }
    .translateWrap {
      will-change: transform;
      display: flex;
      align-items: center;
      flex: 1;
      .translateInner {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: -1.54rem;
      }

      .imgItem {
        width: 100%;
        height: auto;
        user-select: none;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center bottom;
        flex: 1;
      }
    }

  }
}