@import '@/styles/mixins.less';

.experience {
  font-size: 0;
  .fullScreenContainer();
  .years {
    position: relative;
    margin-top: .56rem;
    .yearItem {
      font-family: 'Fabrikat-Light', 'Fabrikat';
      font-weight: 300;
      position: absolute;
      bottom: 2.75rem;
      width: 100%;
      left: 0;
      // pointer-events: none;
      text-align: center;
      opacity: 0;
      display: flex;
      justify-content: center;
      user-select: none;
      line-height: 1.168;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-last-child(1),
      &:nth-last-child(2) {
        opacity: 1;
      }
      .yearInner {
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }
  .timeAxis {
    width: 100%;
    margin-top: -7px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .desc {
    font-weight: normal;
    color: #1E2833;
    min-height: 210px;
    margin-top: .71rem;
    margin-left: 5.13rem;
    height: 2.54rem;
    .descText {
      font-weight: 300;
      letter-spacing: 1px;
      min-width: 300px;
      line-height: 1.538;
      width: 5.2rem;
      font-size: .13rem;
      margin-top: .26rem;
    }
    .descImg {
      object-fit: cover;
    }
  }
  .toggleIcon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
    user-select: none;
    will-change: transform;
    background-size: 100% 100%;
    background-position: center center;
    width: .36rem;
    height: .28rem;

    &.toggleIconleft {
      background-image: url('../../../asset/icons/exp-arrow-left.svg');
    }
    &.toggleIconright {
      background-image: url('../../../asset/icons/exp-arrow.svg');
    }
  }
  &.mobile {
    background: #F9F9F9;
    height: 5.43rem;
    box-sizing: border-box;
    .cover {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
    .years {
      margin-top: .49rem;
      position: relative;
      margin-bottom: .75rem;
      .yearItem {
        line-height: 1.2;
      }
      .smallYearPreSep,
      .smallYearLastSep {
        position: absolute;
        width: .01rem;
        height: .12rem;
        bottom: 2.29rem;
        left: .69rem;
        background-color: rgba(#788187, .4);
      }
      .yearSep {
        position: absolute;
        width: .01rem;
        height: .29rem;
        bottom: 2.29rem;
        left: .69rem;
        background-color: rgba(#788187, .4);
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background-color: #F9F9F9;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }
    }
    .descWrap {
      min-height: unset;
      position: relative;
      margin-left: .37rem;
      margin-right: .41rem;
      // padding-top: .46rem;
      height: 1.72rem;
      // margin-top: 0.29rem;
    }
    .desc,
    .descBottom {
      margin-left: 0;
      margin-right: 0;
      padding-top: 0;
      height: auto;
      box-sizing: border-box;
      margin-top: 0;
      position: relative;
      min-height: unset;
      position: absolute;
      left: 0;
      top: 0;
      will-change: opacity;
      text-align: center;

      .descText {
        // position: absolute;
        // top: .76rem;
        font-weight: 300;
        letter-spacing: 1px;
        margin-top: .21rem;
        line-height: 1.56;
        font-size: .13rem;
        min-width: unset;
        width: auto;
        text-align: left;
      }
      .descImg {
        margin-left: -0.02rem;
      }
    }
    .descBottom {
      opacity: 0;
    }
    .btn {
      cursor: pointer;
      position: absolute;
      bottom: .7rem;
      left: 1.27rem;
      &:last-child {
        left: 1.95rem;
      }
      svg {
        width: .54rem;
        height: .54rem;
      }
    }
  }
}
