// .scroll {
//   height: 7000px;
//   .fixed {
//     position: fixed;
//     left: 0;
//     top: 0;
//     transition: 1s;
//   }
// }

.container {
  .content {
    background-color: #fff;
  }
  .coverBg {
    position: relative;
    z-index: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
