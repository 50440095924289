@import '@/styles/mixins.less';

.social {
  .fullScreenContainer();
  height: auto;
  background-color: #fff;
  z-index: 2;
  .contentWrap {
    margin-left: 1.16rem;
  }
  .bigText {
    margin-bottom: .61rem;
    .line {
      font-weight: 500;
      color: #1E2833;
      line-height: 1.256;
      font-size: .39rem;
    }
  }
  .cardWrap {
    position: relative;
    height: 4.12rem;
    padding-bottom: 1.34rem;

    .cardList {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      // cursor: grab;
      will-change: transform;
      .card {
        background: #F0F2F5;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        margin-right: .23rem;
        padding: .22rem .36rem .45rem .4rem;
        border-radius: .07rem;
        &:last-child {
          margin-right: 0 !important;
        }
        &:hover {
          background: #1E2833;
          .cardTop {
            .left {
              .dateAndLocatioin {
                color: #788080;
              }
            }
            .right {
              color: #fff;
              font-size: 1.12rem;
            }
          }
          .text {
            color: #fff;
          }
        }

        .cardTop {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .left {
            display: flex;
            align-items: flex-start;
            .img {
              object-fit: cover;
              user-select: none;
              pointer-events: none;
              border-radius: .7rem;
              width: .8rem;
              height: .8rem;
              margin-top: .21rem;
            }
            .dateAndLocatioin {
              font-size: .2rem;
              font-family: 'Fabrikat-Regular', 'Fabrikat';
              font-weight: 400;
              color: #788080;
              line-height: 1.15;
              margin-left: .18rem;
              margin-top: .18rem;
            }
          }
          .right {
            font-family: 'Fabrikat-Light', 'Fabrikat';
            font-weight: 300;
            color: #1E2833;
            line-height: 1.17;
            font-size: 1.12rem;
          }
        }
        .text {
          font-weight: 500;
          color: #1E2833;
          line-height: 1.265;
          font-size: .34rem;
          margin-top: .81rem;
        }
      }
    }
  }
  &.mobile {
    .contentWrap {
      margin-left: .25rem;
    }
    .bigText {
      margin-bottom: .46rem;
      .line {
        color: #1E2833;
        font-size: .27rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.26; /* 34.02px */
      }
    }
    .cardWrap {
      margin-left: .25rem;
      height: 2.26rem;
      padding-bottom: .7rem;
      .cardList {
        .card {
          margin-right: .14rem;
          padding: .12rem .2rem .25rem .22rem;
          border-radius: .04rem;
          position: relative;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            background: #F0F2F5;
            .cardTop {
              .left {
                .dateAndLocatioin {
                  color: #788080;
                }
              }
              .right {
                color: #1E2833;
              }
            }
            .text {
              color: #1E2833;
            }
          }
          .cardTop {
            .left {
              .img {
                width: .62rem;
                height: .62rem;
                border-radius: .04rem;
                margin-top: .11rem;
              }
              .dateAndLocatioin {
                margin-top: .09rem;
                margin-left: .11rem;
                line-height: .18rem;
                color: #788080;
                font-family: 'Fabrikat-Regular','Fabrikat';
                font-size: .15rem;
                font-style: normal;
                font-weight: 400;
              }
            }
            .right {
              color: #1E2833;
              font-size: .61rem;
              font-style: normal;
              font-weight: 300;
              line-height: .74rem;
            }
          }
          .text {
            margin-top: .4rem;
            color: #1E2833;
            font-size: .2rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.26; /* 25.211px */
          }
          .arrow {
            position: absolute;
            right: .28rem;
            bottom: .38rem;
            width: .19rem;
            height: .08rem;
            display: flex;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
