@import '@/styles/mixins.less';

.imgWrap {
  position: relative;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  .imgContent {
    position: relative;
    width: 100%;
    // overflow: hidden;
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      // will-change: transform;
      .col {
        font-size: 0;
        .img {
          will-change: transform, opacity;
        }
      }
    }
  }
}