.percentMixin() {
  @functions: ~`(function() {
    function convert(size) {
      return typeof size === 'string' ?
        +size.replace('px', '') : size;
    }
    this.tovw = function(size, base) {
      return convert(size) * 100 / (base || 1280) + 'vw';
    }
    this.tovh = function(size, base) {
      return convert(size) * 100 / (base || 720) + 'vh';
    }
  })()`;
  }

.percentMixin();

@pageMinHeight: 46.875vw;

.fullScreenContainer {
  position: relative;
  width: 100%;
  // height: 100vh;
  // min-width: 1280px;
  // min-height: @pageMinHeight;
  // padding-bottom: calc(56.25vw - 100vh);
  overflow: hidden;
  z-index: 1;
  background-color: #fff;
}