@import '@/styles/mixins.less';

.tech {
  .fullScreenContainer();
  // height: auto;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 1.47rem;
  .img {
    position: absolute;
    object-fit: cover;
    will-change: transform, opacity;
    z-index: -1;
  }
  .content {
    // position: relative;
    margin-left: 1.16rem;
    .textWrap,
    .textUnderWrap {
      will-change: opacity;
      .bigText {
        position: absolute;
        left: 1.16rem;
        top: 1.96rem;
        .line {
          font-weight: 500;
          color: #1E2833;
          line-height: 1.256;
          font-size: .39rem;
        }
      }
      .date {
        position: absolute;
        font-family: 'Fabrikat-Regular', 'Fabrikat';
        font-weight: 400;
        color: #1E2833;
        line-height: 1.538;
        left: 1.16rem;
        font-size: .26rem;
        top: 3.61rem;
      }
    }
    .textUnderWrap {
      opacity: 0;
    }

    .btn {
      // position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-top: 4.69rem;
      .btnItem {
        margin-right: ~`tovw(6)`;
        cursor: pointer;
        font-size: 0;
        background-size: 100% 100%;
        background-position: center center;
        // background-repeat: no-repeat;
        width: .33rem;
        height: .33rem;
        &.btnItemLeft {
          background-image: url('../../asset/icons/toggle-left.svg');
          &:hover {
            background-image: url('../../asset/icons/toggle-left-active.svg');
          }
        }
        &.btnItemRight {
          background-image: url('../../asset/icons/toggle-right.svg');
          &:hover {
            background-image: url('../../asset/icons/toggle-right-active.svg');
          }
        }
        &:last-child {
          margin-right: 0;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}