html {
  @font-face {
    font-family: "Fabrikat-Light";
    src: url("../asset/fonts/Fabrikat Light.otf");
  }

  @font-face {
    font-family: "Fabrikat-Regular";
    src: url("../asset/fonts/Fabrikat Regular.otf");
  }
  will-change: scroll-position;
  body {
    // min-width: 1280px;
    min-height: 720px;
    will-change: scroll-position;
    scroll-behavior: smooth;
    background-color: #1E2833;
    -webkit-tap-highlight-color: transparent;
    // -webkit-touch-callout: none;
    -webkit-text-size-adjust: auto !important;
    -webkit-touch-callout: default !important;
  }
}
// https://github.com/locomotivemtl/locomotive-scroll/issues/350
html.has-scroll-smooth {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}