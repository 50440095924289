@import '@/styles/mixins.less';

.footer {
  .fullScreenContainer();
  height: auto;
  min-height: unset;
  // background-color: #fff;
  box-sizing: border-box;
  z-index: 0;
  background: #1E2833;
  .fixWrap {
    position: absolute;
    left: 0;
    top: -100vh;
    width: 100%;
    .fixItem {
      width: 100%;
      height: 100vh;
      .fixInner {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .footerContent {
    z-index: 0;
    display: flex;
    justify-content: space-between;
    background: #1E2833;
    box-sizing: border-box;
    width: 100%;
    will-change: transform;
    padding: 1.18rem 1.16rem .81rem 1.16rem;
  }
  .cloud1Wrap {
    position: absolute;
    user-select: none;
    pointer-events: none;
    width: 4.65rem;
    height: 4.41rem;
    left: -1.86rem;
    top: .37rem;
    .cloud1 {
      width: 4.65rem;
      position: absolute;
      user-select: none;
      pointer-events: none;
      z-index: 1;
      left: 0;
      top: 0;
    }
  }
  .cloud2Wrap {
    position: absolute;
    user-select: none;
    pointer-events: none;
    width: 4.39rem;
    height: 3.46rem;
    right: -.83rem;
    top: 1.01rem;
    .cloud2 {
      width: 4.39rem;
      position: absolute;
      user-select: none;
      pointer-events: none;
      z-index: 1;
      left: 0;
      top: 0;
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .left {
    .bigText {
      font-size: .54rem;
      font-weight: 500;
      color: #929899;
      line-height: 1.26;
      letter-spacing: .08rem;
      margin-bottom: 1.88rem;
      .line {
        display: flex;
        align-items: center;
        .hightLight {
          color: #fff;
        }
      }
    }
    .logo {
      color: #788084;
      width: 1.45rem;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .right {
    .links {
      display: flex;
      align-items: flex-start;
      .linkColumn {
        display: flex;
        flex-direction: column;
        margin-right: .85rem;
        .linkItem {
          font-size: .15rem;
          font-weight: 300;
          color: #788080;
          line-height: 2.1875;
          cursor: pointer;
          margin-bottom: .11rem;
          &:hover {
            color: #D0D7D8;
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .extraInfo {
      .sepLine {
        width: 100%;
        height: 1px;
        background-color: rgba(#788080, .3);
        margin-bottom: .29rem;
      }
      .copyRight {
        font-size: .12rem;
        // font-family: 'Fabrikat-Regular', 'Fabrikat';
        font-weight: 300;
        color: #788080;
        line-height: 1.375;
        .lineLink {
          cursor: pointer;
          &:hover {
            color: #D0D7D8;
          }
        }
      }
    }
  }
  &.mobile {
    .fixWrap {
      .fixItem {
        .fixInner {
          .footerContent {
            padding: 0;
            display: flex;
            flex-direction: column;
            .cloud1Wrap {
              width: 1.15rem;
              height: 3.71rem;
              left: 0;
              top: -.29rem;
              .cloud1 {
                width: 1.15rem;
              }
            }
            .cloud2Wrap {
              width: 2.04rem;
              height: 3.14rem;
              right: 0;
              top: -.64rem;
              .cloud2 {
                width: 2.04rem;
              }
            }
            .left {
              height: 1.98rem;
              position: relative;
              width: 100%;
              .logo,
              .bigText {
                position: absolute;
              }
              .logo {
                left: .32rem;
                top: .54rem;
                width: .96rem;
                height: .17rem;
                display: flex;
              }
              .bigText {
                left: .31rem;
                top: .9rem;
                .line {
                  color: #929899;
                  font-size: .24rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: .3rem; /* 30.672px */
                  letter-spacing: .03rem;
                  .hightLight {
                    color: #fff;
                  }
                }
              }
            }
            .right {
              .links {
                margin-left: .38rem;
                margin-bottom: .53rem;
                .linkColumn {
                  &:first-child {
                    width: .71rem;
                    margin-right: .35rem;
                  }
                  &:nth-child(2) {
                    // width: 1rem;
                    margin-right: .31rem;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                  .linkItem {
                    font-size: .14rem;
                    color: #788080;
                    font-style: normal;
                    font-weight: 300;
                    line-height: .16rem; /* 16.87px */
                    letter-spacing: 0.0042rem;
                    margin-bottom: .19rem;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
              .extraInfo {
                padding-left: .38rem;
                padding-bottom: .79rem;
                .sepLine {
                  height: .01rem;
                  min-height: 1px;
                  margin-bottom: .22rem;
                }
                .copyRight {
                  color: #788080;
                  font-size: .12rem;
                  font-style: normal;
                  .lineLink {
                    &:hover {
                      color: #788080;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}