@import '@/styles/mixins.less';

.title {
  display: flex;
  align-items: center;
  height: .22rem;
  padding: .025rem 0;
  svg {
    height: 100%;
    width: auto;
  }

  &.mobile {
    height: .14rem;
    padding: 0;
    margin-left: .26rem;
    svg {
      height: 100%;
    }
  }
}