/**
 * HTML5 / CSS3 Circle with Partial Border
 * http://stackoverflow.com/q/13059190/1397351
 */
$percentage: 33;
$percentageText: $percentage;

$arcThickness: 3px;
$arcColor: rgba(255, 255, 255, .5);
$arcColorBackground: transparent;

// $circleSize: min(40vw, 71.1vh);
$circleSize: 100%;
// $circleSize: 40vw;
// $circleSizeForHeight: 71.1vh;
$circleColor: rgba(255, 255, 255, .15);


// preprocess numbers
$arcStartPosition: 90deg;

$circleOutterSize: calc($circleSize + $arcThickness*2);

$flip: rotateX(0deg);
$_arcColor: $arcColor;
@if $percentage == 50 {
  $flip: rotateX(0deg);

} @else if $percentage > 50 {
  $flip: rotateX(180deg);
  $percentage: 100 - $percentage;

  $arcColor: $arcColorBackground;
  $arcColorBackground: $_arcColor;
}

$arcSkewX: ($percentage * 3.6 - 90 ) * -1deg;
$arcSkewXfix: $arcSkewX * -1;

.banner-circle {
	position: relative;
	width: $circleOutterSize; height: $circleOutterSize;
	border-radius: 50%;
	background: $arcColorBackground;
  overflow: hidden;
  margin-left: -$arcThickness;
  margin-top: -$arcThickness;
}
.banner-circle:before {
	content: '';
	display: block;
	position: relative;
	width: $circleSize; height: $circleSize;
	border-radius: 50%;
	background: transparent;
  box-sizing: border-box;
	// top: $arcThickness;
	// left: $arcThickness;
  border: $arcThickness solid $circleColor;
}
.banner-arc {
	overflow: hidden;
    width: 50%; height: 50%;
	//background: #333;
	position: absolute;
	top: -10000em; right: 50%; bottom: 50%; left: -10000em;
	padding: 10000em 0 0 10000em;
	transform-origin: 100% 100%;
	transform: rotateZ($arcStartPosition) $flip skewX($arcSkewX);
}
.banner-arc:before {
	//box-sizing: border-box;
	display: block;
	border: solid $arcThickness $arcColor;
	width: 200%; height: 200%;

	box-sizing: border-box;
	border-radius: 50%;
	transform: skewX($arcSkewXfix);
	content: '';
}
