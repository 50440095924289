@import '@/styles/mixins.less';

@circleSize: 40vw;
@circleSizeForHeight: 0.711 * @pageMinHeight;

.banner {
  .fullScreenContainer();
  height: 100vh;
  min-height: @pageMinHeight;
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .25);
    user-select: none;
    pointer-events: none;
    z-index: 1;
  }
  color: #fff;
  .logo {
    position: absolute;
    // top: ~`tovw(70, 1920)`;
    // left: ~`tovw(70, 1920)`;
    // width: ~`tovw(251, 1920)`;
    top: .59rem;
    left: .98rem;
    width: 2.12rem;
    height: .375rem;
    font-size: 0;
    z-index: 2;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .bannerImg,
  .bannerImgUnder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    will-change: transform, opacity;
  }
  .bannerImgUnder {
    z-index: 0;
    opacity: 1;
  }
  .circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: -0.625vw;
    z-index: 2;
    .number,
    .numberUnder,
    .cn1,
    .cn2,
    .en,
    .textWrap {
      position: absolute;
      color: #fff;
      will-change: transform, opacity;
    }

    .number,
    .numberUnder {
      top: 3.9%;
      left: -5.08%;
      font-family: 'Fabrikat-Light', 'Fabrikat';
      font-weight: 300;
      font-size: 1.38rem;
      line-height: 1.62rem;
      z-index: 1;
      margin-left: .08rem;
    }

    .numberUnder {
      z-index: 0;
      opacity: 0;
    }

    .textWrap {
      height: 37.7%;
      width: ~`tovw(803)`;
      top: 43.75%;
      left: -5.08%;
    }

    .cn1,
    .cn2 {
      // font-size: ~`tovw(62)`;
      font-weight: normal;
      // line-height: ~`tovw(75)`;
      // letter-spacing: ~`tovw(5)`;
      top: 0;
      // width: ~`tovw(803)`;
      font-weight: 500;
      font-size: .62rem;
      line-height: .75rem;
      letter-spacing: .05rem;
      width: 8.03rem;
    }
    .cn2 {
      top: 38.8%;
    }
    .en {
      top: 90.15%;
      // font-size: ~`tovw(16)`;
      font-family: 'Fabrikat-Light', 'Fabrikat';
      font-weight: 300;
      color: #fff;
      // line-height: ~`tovw(19)`;
      // width: ~`tovw(803)`;
      font-size: .16rem;
      line-height: .19rem;
      width: 8.03rem;
    }

  }
  .toggleIcon {
    position: absolute;
    z-index: 9;
    width: 4.68vw;
    height: 4.68vw;
    cursor: pointer;
    user-select: none;
    will-change: transform;
    line-height: 18.4px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.mobile {
    .logo {
      top: .28rem;
      left: .23rem;
      width: 1.61rem;
      height: .29rem;
    }
    .circle {
      position: static;
      transform: none;
      margin: 0;
      .number,
      .numberUnder {
        top: unset;
        left: .23rem;
        bottom: 1.56rem;
        font-family: 'Fabrikat-Light', 'Fabrikat';
        font-weight: 300;
        font-size: .74rem;
        line-height: .9rem;
        z-index: 2;
        margin: 0;
      }

      .numberUnder {
        z-index: 0;
        opacity: 0;
      }

      .textWrap {
        height: 1rem;
        // width: 3.6rem;
        left: .23rem;
        bottom: .63rem;
        top: unset;
        z-index: 2;
      }

      .cn1,
      .cn2 {
        font-size: .32rem;
        font-weight: normal;
        line-height: .39rem;
        letter-spacing: .03rem;
        top: 0;
        // width: 3.6rem;
        font-weight: 500;
      }
      .cn2 {
        top: .4rem;
      }
      .en {
        top: .86rem;
        font-size: .12rem;
        letter-spacing: .006rem;
        font-family: 'Fabrikat-Light', 'Fabrikat';
        font-weight: 400;
        color: #fff;
        line-height: .14rem;
        width: 3.6rem;
      }
    }
    .progressWrap {
      position: absolute;
      left: .23rem;
      bottom: .42rem;
      width: 3.3rem;
      height: .02rem;
      background: rgba(255, 255, 255, 0.30);
      z-index: 2;
      .progress {
        height: 100%;
        background-color: #fff;
        transition: 1s;
      }
    }
  }
}
