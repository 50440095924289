@import '@/styles/mixins.less';

.news {
  .fullScreenContainer();
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .newContent {
    display: flex;
    align-items: flex-start;
    margin-top: 1.13rem;
    margin-left: 1.16rem;
    width: calc(100% - 1.16rem);
    .left {
      // height: 100%;
      margin-right: .37rem;
      position: relative;
      .title {
        display: flex;
        align-items: center;
        .en {
          font-size: ~`tovw(25)`;
          font-family: 'Fabrikat-Regular', 'Fabrikat';
          font-weight: 400;
          color: #1E2833;
          line-height: ~`tovw(30)`;
        }
        .sep {
          width: 1px;
          height: ~`tovw(18)`;
          background-color: #1E2833;
          margin: 0 ~`tovw(8)`;
        }
        .cn {
          font-size: ~`tovw(22)`;
          font-weight: normal;
          color: #1E2833;
          line-height: ~`tovw(27)`;
        }
      }
      .textWrap {
        .bigText {
          margin-top: .54rem;
          margin-bottom: .23rem;
          .bigTextLine {
            font-weight: 500;
            color: #1E2833;
            line-height: 1.26;
            font-size: .39rem;
          }
        }
        .smallText {
          .smallTextLine {
            font-size: .13rem;
            font-weight: 300;
            color: #1E2833;
            line-height: 1.69;
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        user-select: none;
        margin-top: 1.41rem;
        .btnItem {
          cursor: pointer;
          font-size: 0;
          background-size: 100% 100%;
          background-position: center center;
          width: .34rem;
          height: .34rem;
          // background-repeat: no-repeat;
          &.btnItemLeft {
            margin-right: .06rem;
            background-image: url('../../asset/icons/toggle-left.svg');
            &:hover {
              background-image: url('../../asset/icons/toggle-left-active.svg');
            }
          }
          &.btnItemRight {
            background-image: url('../../asset/icons/toggle-right.svg');
            &:hover {
              background-image: url('../../asset/icons/toggle-right-active.svg');
            }
          }
          svg {
            width: 100%;
            height: 100%;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .right {
      flex: 1;
      overflow: hidden;
      position: relative;
      margin-top: .06rem;
      .imgWrap {
        display: flex;
        align-items: flex-start;
        .imgItem {
          position: absolute;
          left: 0;
          top: 0;
          cursor: pointer;
          overflow: hidden;
          font-size: 0;
          transform-origin: 0 0;
          border-radius: .07rem;
          .img {
            object-fit: cover;
            user-select: none;
            transition: .7s;
            transform-origin: center center;
            transition-timing-function: cubic-bezier(.14,0,0,1.01);
            will-change: transform;
            &:hover {
              transform: scale(1.1);
            }
          }
        }

      }
      .dateText {
        position: absolute;
        bottom: 0;
        .month {
          font-size: .24rem;
          font-weight: 400;
          color: #788080;
          margin-bottom: .03rem;
          font-family: 'Fabrikat-Regular', 'Fabrikat';
          line-height: 1.17;
        }
        .brandAndYear {
          display: flex;
          align-items: center;
          .brand {
            font-size: .12rem;
            font-weight: 300;
            color: #788080;
            line-height: 1.18;
          }
          .sep {
            width: 1px;
            background-color: #788080;
            height: .09rem;
            margin: 0 .09rem;
          }
        }
      }
    }
  }
}