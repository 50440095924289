@import '@/styles/mixins.less';

.titleWrap {
  will-change: transform, opacity;
  padding-bottom: .62rem;
  margin-left: 1.16rem;
  .desc {
    font-weight: 500;
    color: #1E2833;
    line-height: 1.2564;
    margin-left: -2px;
    font-size: .39rem;
  }
  &.mobile {
    margin-left: .27rem;
    padding-top: .64rem;
    padding-bottom: .16rem;
    .desc {
      line-height: 1.26;
      font-size: .27rem;

    }
  }
}
.imgWrap {
  // .fullScreenContainer();

  position: relative;
  width: 100%;
  background-color: #fff;
  // min-width: 1280px;
  // overflow: hidden;
  &.notAnimate {
    .imgSticky {
      transform: none !important;
      .sideLeft,
      .sideRight,
      .centerImg {
        transform: none !important;
      }
    }
  }
  .imgContent {
    height: 200vh;
    position: relative;
    .imgStickyParent {
      position: absolute;
      top: -100vh;
      bottom: -100vh;
      height: 400vh;
      width: 100%;
      .imgSticky {
        height: 100vh;
      }
    }
  }
  .imgInner {
    display: flex;
    align-items: flex-start;
  }
  .sideLeft,
  .sideRight,
  .centerImg {
    display: flex;
    flex-direction: column;
    will-change: transform;
    height: 100vh;
    .img {
      width: 100%;
      object-fit: cover;
      cursor: pointer;
      flex-shrink: 0;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}