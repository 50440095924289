.news {
  height: 6.24rem;
  padding-top: .63rem;
  position: relative;
  box-sizing: border-box;
  .swiperWrap {
    .swiperItem {
      position: relative;
      width: 3.06rem;
      border-radius: .07rem;
      font-size: 0;
      .img {
        width: 100%;
        height: 3.23rem;
        border-radius: .07rem;
      }
      .index {
        position: absolute;
        right: .18rem;
        bottom: .13rem;
        color: #FFF;
        font-family: 'Fabrikat-Regular','Fabrikat';
        font-size: .14rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        line-height: .17rem;
      }
    }
    :global {
      .swiper-slide {
        width: 3.06rem;
      }
    }
  }
  .textWrap,
  .nextTextWrap {
    margin-left: .26rem;
    height: 1.75rem;
    padding-top: .19rem;
    will-change: opacity;
    opacity: 1;
    position: absolute;
    left: 0;
    // bottom: 0;
    box-sizing: border-box;
    .bigText {
      margin-bottom: .11rem;
      color: #1E2833;
      font-size: .2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.26;
    }
    .smallText {
      color: #1E2833;
      font-size: .12rem;
      font-style: normal;
      font-weight: 300;
      line-height: .2rem;
    }
    .date {
      margin-top: .19rem;
      color: #CCC;
      font-size: .12rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.18; /* 14.16px */
      font-family: 'Fabrikat-Regular', 'Fabrikat';
    }
  }
  .nextTextWrap {
    opacity: 0;
  }
}
